import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Manager',
        component: () => import('../views/Manager.vue'),
        redirect: '/manager/home',  // 重定向到主页
        children: [
            { path: '/403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
            {
                path: '/manager/home',
                name: 'Home',
                meta: { name: '系统首页' },
                component: () => import('../views/manager/Home')
            },
            { path: '/admin', name: 'Admin', meta: { name: '管理员信息' }, component: () => import('../views/manager/Admin') },
            { path: '/user', name: 'User', meta: { name: '用户信息' }, component: () => import('../views/manager/User') },
            { path: '/notice', name: 'Notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
            { path: '/label', name: 'Label', meta: { name: '标签信息' }, component: () => import('../views/manager/Label') },
            { path: '/activity', name: 'Activity', meta: { name: '活动信息' }, component: () => import('../views/manager/Activity') },
            { path: '/classManager', name: 'ClassManager', meta: { name: '班级信息' }, component: () => import('../views/manager/ClassManager') },
            { path: '/student', name: 'Student', meta: { name: '学生信息' }, component: () => import('../views/manager/Student') },
            { path: '/school', name: 'School', meta: { name: '学校信息' }, component: () => import('../views/manager/School') },
            { path: '/organization', name: 'Organization', meta: { name: '组织信息' }, component: () => import('../views/manager/Organization') },
            { path: '/role', name: 'Role', meta: { name: '角色信息' }, component: () => import('../views/manager/Role') },
            { path: '/order', name: 'Order', meta: { name: '订单信息' }, component: () => import('../views/manager/Order') },
            { path: '/refund', name: 'Refund', meta: { name: '退款信息' }, component: () => import('../views/manager/Refund') },
            { path: '/signin', name: 'Signin', meta: { name: '签到信息' }, component: () => import('../views/manager/Signin') },
            { path: '/live', name: 'live', meta: { name: '直播信息' }, component: () => import('../views/manager/live') },
            { path: '/log', name: 'Log', meta: { name: '日志信息' }, component: () => import('../views/manager/Log') },
            { path: '/certificate', name: 'Certificate', meta: { name: '证书信息' }, component: () => import('../views/manager/Certificate') },
            { path: '/website', name: 'Website', meta: { name: '网站信息' }, component: () => import('../views/manager/Website') },
            { path: '/activity-video', name: 'ActivityVideo', meta: { name: '活动视频' }, component: () => import('../views/manager/ActivityVideo') },
            { path: '/activity-tag', name: 'ActivityTag', meta: { name: '活动标签' }, component: () => import('../views/manager/ActivityTag') },
            { path: '/activity-evaluation', name: 'ActivityEvaluation', meta: { name: '活动评价' }, component: () => import('../views/manager/ActivityEvaluation') },
            { path: '/update-scopes-skus', name: 'UpdateScopesSku', meta: { name: '修改活动规格' }, component: () => import('../views/manager/updateScopesSkus') },
            {
                path: '/adminPerson',
                name: 'AdminPerson',
                meta: { name: '个人信息' },
                component: () => import('../views/manager/AdminPerson')
            },

            {
                path: '/password',
                name: 'Password',
                meta: { name: '修改密码' },
                component: () => import('../views/manager/Password')
            },

        ]
    },

    { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
    { path: '/register', name: 'Register', meta: { name: '注册' }, component: () => import('../views/Register.vue') },
    { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
    mode: 'hash',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
// router.beforeEach((to ,from, next) => {
//   let user = JSON.parse(sessionStorage.getItem("xm-user") || '{}');
//   if (to.path === '/') {
//     if (user.role) {
//       if (user.role === 'USER') {
//         next('/front/home')
//       } else {
//         next('/home')
//       }
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
