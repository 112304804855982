import axios from 'axios'
import router from "@/router";

import {Loading} from 'element-ui';

let loading = null

// 创建可一个新的axios对象
const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,   // 后端的接口地址  ip:port
    timeout: 30000                          // 30s请求超时
})







import pageLoading from '@/assets/css/pageLoading.css'


//开始 加载loading
let startLoading = () => {
    loading = Loading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.8)'//加载动画的背景
    })
}
//结束 取消loading加载
let endLoading = () => {
    loading.close()
}

//showFullScreenLoading() 与 tryHideFullScreenLoading() 目的是合并同一页面多个请求触发loading

let needLoadingRequestCount = 0 //声明一个变量

let showFullScreenLoading = () => {
    if (needLoadingRequestCount === 0) { //当等于0时证明第一次请求 这时开启loading
        startLoading()
    }
    needLoadingRequestCount++ //全局变量值++
}

let tryHideFullScreenLoading = () => {
    if (needLoadingRequestCount <= 0) return //小于等于0 证明没有开启loading 此时return
    needLoadingRequestCount-- //正常响应后 全局变量 --
    if (needLoadingRequestCount === 0) {  //等于0 时证明全部加载完毕 此时结束loading 加载
        endLoading()
    }
}
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';        // 设置请求头格式
    let user = JSON.parse(sessionStorage.getItem("xm-user") || '{}')  // 获取缓存的用户信息
    config.headers['token'] = user.token  // 设置请求头
    showFullScreenLoading()
    return config
}, error => {
    console.error('request error: ' + error) // for debug
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;

        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if (res.status === 401) {
            // token 过期
            sessionStorage.removeItem("xm-user")  // 清除缓存用户信息
            router.push('/login')
        }

        if(res.status === 405){
            this.$message.warning(`权限不足`)
            return
        }

        if(res.status === 500){
            this.$message.warning(`服务器异常，请联系管理员`)
            return
        }

        tryHideFullScreenLoading()
        return res;
    },
    error => {
        console.error('response error: ' + error) // for debug
        endLoading()
        return Promise.reject(error)
    }
)


export default request