<template>
  <div id="app">
    <router-view @update:user="updateUser"/>
  </div>
</template>

<script>
export default {

  name: "Manager",
  data() {
    return {
      user: null,
    };
  },
  methods: {
    updateUser() {
      this.user = JSON.parse(sessionStorage.getItem("xm-user") || "{}"); // 重新获取下用户的最新信息
    },
  }
}
</script>